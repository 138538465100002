import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'
import {Link as LinkS} from 'react-scroll'



// screen size transition @media
// margin-top: -80px is for stuff underneath navbar
export const Nav = styled.nav`
    background: #000;
    height: 80px;
    margin-top: -10px;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 1;


    @media screen and (max-width: 960px){
        transition: 0.8s all ease;
    }
`;

export const NavbarContainer = styled.div`
    height: 80px;
    display: flex;
    justify-content: space-between;
    z-index: 1;
    width : 100%;
    padding: 0 24px;
    max-width: 1100px;
`;


export const NavLogo = styled(LinkR)`
color: #fff;
justify-self: flex-start;
cursor: pointer;
font-size: 1.5rem;
display: flex;
align-items: center;
margin-left: 24px;
font-weight: bold;
text-decoration: none;
`;

// this is for moblie website on your phone

// this is the icon for the sidebar on the right
export const MoblieIcon = styled.div`
display: none;

@media screen and (max-width: 768px){
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
}
`;

// this is the menu that will be listed once click on the icon
export const NavMenu = styled.ul`
display: flex;
align-items: center;
list-style : none;
text-align: center;
margin-right: -22px;

@media screen and (max-width: 768px){
    display:none;
}
`;

export const NavItem = styled.li`
height: 30px;
&:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}
`;

// hover over and highlightings color green on top buttons on the navbar
export const NavLinks = styled(LinkS)`
color: #fff;
display: flex;
align-items; center;
text-decoration: none;
padding: 0 1rem;
height: 100%
cursor: pointer;

&.active{
    border: 3px solid #01bf71
}

&:hover {
    color: #01bf71;
    transition: 0.2s ease-in-out;
}
`;

export const NavBtn = styled.nav`
    display: flex;
    align-items: center;

@media screen and (max-width: 768px){
    display: none;
}
`;

// color for sign in button
export const NavBtnLink = styled(LinkS)`
    border-radius: 50px;
    background: #01bf71;
    white-space: nowrap;
    padding: 10px 22px;
    color: #010606;
    font-dize: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover{
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #010606;
    }
`;






